<script lang="ts" setup>
  import { storeToRefs } from 'pinia'
  import { useViewportWarningStore } from '~/store/ViewportWarning'
  import useTranslation from '~/composables/Translation/Translation'
  import { OverlayContentModalFacet } from '~/components/OverlayContent/OverlayContentModal/OverlayContentModalFacet'
  import { OverlayContentModalAction } from '~/components/OverlayContent/OverlayContentModal/OverlayContentModalAction'
  import useModal from '~/composables/Modal/Modal'
  import { UiOverlayFacet } from '~/components/Ui/UiOverlay/UiOverlayFacet'
  import { OverlayId } from '~/model/Overlay/Overlay'

  const viewportWarningStore = useViewportWarningStore()

  const { openModal, closeModal } = useModal()
  const { t } = useTranslation()
  const { isShown } = storeToRefs(viewportWarningStore)
  const { toggleShown } = viewportWarningStore

  const onWarningDismissed = () => {
    toggleShown(false)
  }

  watch(
    isShown,
    (value) => {
      if (value) {
        openModal('viewport-warning', {
          facets: [UiOverlayFacet.ViewportWarning],
          disableScroll: false,
          props: {
            facets: [OverlayContentModalFacet.Alert, OverlayContentModalFacet.ViewportWarning],
            actions: <Array<OverlayContentModalAction>>[
              {
                callback: onWarningDismissed,
                label: t('viewport-warning.button.label'),
                btnFacets: ['mono-black', 'medium'],
              },
            ],
          },
        }, OverlayId.ViewportWarning)
      } else {
        closeModal(OverlayId.ViewportWarning)
      }
    },
    { immediate: true },
  )
</script>

<template>
  <div class="c-viewport-warning" />
</template>

<style lang="scss" scoped>
  @use '/assets/scss/base/mq' as mq;
  @use '/assets/scss/util/layer' as layer;
  @use '/assets/scss/util/color/color' as col;
  @use '/assets/scss/util/anaglyph/anaglyph' as anaglyph;

  $o: 4px;

  .c-viewport-warning {
    pointer-events: none;
    z-index: layer.z-index('viewport-warning');
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    flex-flow: column nowrap;
  }
</style>
